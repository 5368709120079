import sendMessage from './sendMessage';
import switchOption from './switchOption';
import timeout from './timeout';
import userResponse from './userResponse';
import callAPI from './callAPI';
import media from './media';
import start from './start';
import transferCalled from './endTransferCalled';
import endCalled from './endCalled';
import chooseDepartment from './endChooseDepartment';
import createTAG from './createTAG';
import conditional from './conditional';
import sendConnectionEvent from './sendConnectionEvent';
import agentAi from "./AgentAi";

export default {
  sendMessage,
  switchOption,
  timeout,
  userResponse,
  callAPI,
  media,
  start,
  transferCalled,
  endCalled,
  chooseDepartment,
  createTAG,
  conditional,
  sendConnectionEvent,
  agentAi
};
