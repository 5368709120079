import React, { useState, useEffect, useRef } from 'react';
import { Picker } from 'emoji-mart';
import { TextField, Box } from '@material-ui/core';
import Button from '../CustomButtons/Button';

const MessageModal = ({ onClose }) => {
    const [message, setMessage] = useState('');
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [messages, setMessages] = useState([]);
    const emojiPickerRef = useRef(null);

    const EmojiClick = (emoji) => {
        setMessage((prevMessage) => prevMessage + emoji.native);
    };

    const InputChange = (event) => {
        setMessage(event.target.value);
    };

    const toggleEmojiPicker = (e) => {
        e.stopPropagation();
        setShowEmojiPicker(!showEmojiPicker);
    };

    useEffect(() => {
        const ClickOutside = (event) => {
            if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target)) {
                setShowEmojiPicker(false);
            }
        };

        document.addEventListener('click', ClickOutside);
        return () => {
            document.removeEventListener('click', ClickOutside);
        };
    }, []);

    const SendMessage = () => {
        if (message.trim()) {
            setMessages((prevMessages) => [...prevMessages, message]);
            setMessage('');
        } else {
            alert('Por favor, digite uma mensagem antes de enviar.');
        }
    };

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                zIndex: 1000,
                padding: '20px',
            }}
        >
            <Box
                display="flex"
                flexDirection="column"
                style={{
                    backgroundColor: 'white',
                    borderRadius: '12px',
                    width: '100%',
                    maxWidth: '450px',
                    padding: '20px',
                    boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)',
                }}
            >
                <Button
                    onClick={onClose}
                    style={{
                        alignSelf: 'flex-end',
                        padding: '5px',
                        minWidth: '30px',
                        minHeight: '30px',
                        fontSize: '16px',
                        color: '#FF0000',
                        backgroundColor: 'transparent',
                        border: 'none',
                        cursor: 'pointer',
                    }}
                >
                    X
                </Button>

                <Box
                    display="flex"
                    flexDirection="column"
                    style={{
                        width: '100%',
                        maxHeight: '200px',
                        overflowY: 'auto',
                        marginBottom: '15px',
                    }}
                >
                    {messages.map((msg, index) => (
                        <Box
                            key={index}
                            style={{
                                padding: '10px 15px',
                                borderRadius: '20px',
                                backgroundColor: '#f5f5f5',
                                maxWidth: '80%',
                                wordBreak: 'break-word',
                                marginBottom: '8px',
                                boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                            }}
                        >
                            {msg}
                        </Box>
                    ))}
                </Box>

                {message.trim() && (
                    <Box
                        style={{
                            marginBottom: '10px',
                            padding: '10px 15px',
                            borderRadius: '20px',
                            backgroundColor: 'rgba(255, 0, 0, 0.2)',
                            maxWidth: '80%',
                            wordBreak: 'break-word',
                            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                            alignSelf: 'flex-start',
                        }}
                    >
                        {message}
                    </Box>
                )}

                <Box
                    display="flex"
                    alignItems="center"
                    style={{ position: 'relative', width: '100%' }}
                >
                    <TextField
                        placeholder="Digite sua mensagem..."
                        value={message}
                        onChange={InputChange}
                        fullWidth
                        variant="outlined"
                        style={{
                            borderRadius: '20px',
                            flexGrow: 1,
                            backgroundColor: '#f9f9f9',
                        }}
                        multiline
                        rowsMax={4}
                    />

                    <Box style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                        <Button
                            onClick={toggleEmojiPicker}
                            style={{
                                width: '40px',
                                height: '40px',
                                borderRadius: '50%',
                                padding: '0',
                                backgroundColor: '#ececec',
                                color: '#555',
                                border: 'none',
                                cursor: 'pointer',
                                marginRight: '10px',
                            }}
                        >
                            😊
                        </Button>
                        <Button
                            color={'hust'}
                            onClick={SendMessage}
                        >
                            ➤
                        </Button>
                    </Box>

                    {showEmojiPicker && (
                        <div
                            ref={emojiPickerRef}
                            style={{
                                position: 'absolute',
                                bottom: '60px',
                                right: '0',
                                zIndex: 10,
                            }}
                        >
                            <Picker onSelect={EmojiClick} />
                        </div>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default MessageModal;
