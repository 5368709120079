import React, { useEffect, useState } from "react";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import Success from "../../components/Typography/Success";
import { cardTitle, grayColor, hexToRgb, infoColor, successColor } from "../../assets/jss/material-dashboard-pro-react";
import { convertDate, getFotoLink, iconMessage, isValidDate, nomeProprio, zeroEsquerda } from "../../components/Funcoes";
import moment from "moment";
import { WebService } from "../../components/WebService";
import Button from "../../components/CustomButtons/Button";
import { CustomButtons } from "../../components";
import Badge from "@material-ui/core/Badge";
import { useStyles } from "../NewChat/styles";
import {
  AddIcCall,
  Close,
  Business,
  Chat,
  PhoneDisabledTwoTone,
  SyncAlt,
  FindInPage,
  ImportExport
} from "@material-ui/icons";
import { Modal, IconButton, Input, ListItemText } from "@material-ui/core";
import { makeStyles, Tooltip } from "@material-ui/core";
import SelectInput from "@material-ui/core/Select/SelectInput";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import CustomInput from "../../components/CustomInput/CustomInput";
import CardIcon from "../../components/Card/CardIcon";
import { AsYouType } from "libphonenumber-js";
import Icon from "@material-ui/core/Icon";
import CardHeader from "../../components/Card/CardHeader";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Warning from "../../components/Typography/Warning";
import Danger from "../../components/Typography/Danger";
import CardFooter from "../../components/Card/CardFooter";
import extendedTablesStyle from "../../assets/jss/material-dashboard-pro-react/views/extendedTablesStyle";
import buttonStyle from "../../assets/jss/material-dashboard-pro-react/components/buttonStyle";
import dashboardStyle from "../../assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import HistoricoConversa from "../Componentes/Chat/HistoricoConversa";
import Swal from "sweetalert2";
import Datetime from "react-datetime";
import Checkbox from '@material-ui/core/Checkbox';
import api from "../../components/api";
import ReactTags from "react-tag-autocomplete";
import ModalBulkActionsReportCalleds from "../Modal/BulkActionsReportCalleds";
import ModalCalledHistory from "../Modal/CalledHistory";
import FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import Check from "@material-ui/icons/Check";

const statusLabel = {
  atendimento_pendente: 'Fila de espera',
  atendimento_finalizado: 'Finalizado',
  atendimento: 'Em atendimento',
  escolha_departamento: 'Aguardando escolha de departamento',
  avaliacao_atendimento: 'Avaliação de antendimento',
};


const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0"
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem"
  },
  cardCategory: {
    color: "#999999",
    marginTop: "10px"
  },
  icon: {
    color: "#333333",
    margin: "10px auto 0",
    width: "130px",
    height: "130px",
    border: "1px solid #E5E5E5",
    borderRadius: "50%",
    lineHeight: "174px",
    "& svg": {
      width: "55px",
      height: "55px"
    }
  },
  marginTop30: {
    marginTop: "30px"
  },
  testimonialIcon: {
    marginTop: "30px",
    "& svg": {
      width: "40px",
      height: "40px"
    }
  },
  cardTestimonialDescription: {
    fontStyle: "italic",
    color: "#999999"
  },
  ...extendedTablesStyle,
  ...buttonStyle,
  ...dashboardStyle
};

const useStyles1 = makeStyles(styles);
let showNotice = true;

export default function RelatorioAtendimento({history}) {
  const classes = { ...useStyles(), ...useStyles1() };
  const [chamados, setChamados] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);

  const [status, setStatus] = useState("atendimento");
  const [nomeCliente, setNomeCliente] = useState("");
  const [telefone, setTelefone] = useState("");
  const [protocolo, setProtocolo] = useState("");
  const [conexao, setConexao] = useState("");
  const [departamento, setDepartamento] = useState("");
  const [atendente, setAtendente] = useState("");
  const [tags, setTags] = useState([]);

  const [offset, setOffset] = useState(0);
  const [dateFilterType, setDateFilterType] = useState('start');
  const [usuarios, setUsuarios] = useState([]);
  const [tagSystem, setTagSystem] = useState([]);
  const [conexoes, setConexoes] = useState([]);

  const [periodoInicial, setPeriodoInicial] = useState(new Date(new Date().setDate(1)));
  const [periodoFinal, setPeriodoFinal] = useState(new Date());

  const [historicoChamado, setHistoricoChamado] = useState({ atendente: "", id_chamado: "", mensagens: [], chamado: [] });

  const [modalTransferirChamado, setModalTransferirChamado] = useState({
    id_departamento: "",
    id_usuario: "",
    id_chamado: ""
  });

  const [checkedAbre, setCheckedAbre] = React.useState(true);
  const [checkedFecha, setCheckedFecha] = React.useState(true);

  const getTags = async () => {
    const {data} = await api.get('/tag');
    setTagSystem(data);
  };

  useEffect(() => {
    getTags();
  }, []);

  const checkAb = () => {
    setCheckedAbre(!checkedAbre);
  };
  const checkFim = () => {
    setCheckedFecha(!checkedFecha);
  };

  const getChamados = async () => {
    setOffset(0);
    let filtro = {
      status, nomeCliente, telefone, protocolo, conexao, departamento, atendente, periodoInicial, periodoFinal, tags,
      dateFilterType
    };
    if (protocolo) filtro = { protocolo };
    const { ok, chamados } = await WebService(`/chamado/getChamados`, filtro, "POST");
    if (ok) setChamados(chamados);
  };

  const getUsuarios = async () => {
    const { ok, usuarios } = await WebService(`/usuario/getUsuarios`);
    if (ok) setUsuarios(usuarios);
  };

  const getConexoes = async () => {
    const { ok, whatsapp_conexao } = await WebService(`/whatsapp_api/getConexoes`);
    if (ok) setConexoes(whatsapp_conexao);
  };

  const getDepartamentos = async () => {
    const { ok, departamentos } = await WebService(`/departamento/getDepartamentos`);
    if (ok) {
      const tempDepartamentos = [];
      const getChild = (d, n) => {
        d.map(prop => {
          const { nome, id_departamento, subDepartamentos } = prop;
          if (subDepartamentos) getChild(subDepartamentos, `${n ? n + ' -> ' : ''}${nome}`);
          else tempDepartamentos.push({ nome: `${n ? n + ' -> ' : ''}${nome}`, id_departamento });
        });
      };
      getChild(departamentos);
      setDepartamentos(tempDepartamentos);
    }
  };

  const transferirAtendimento = async () => {
    const { ok } = await WebService(`/chamado/transferirAtendimento`, modalTransferirChamado, "POST");

    if (ok) {
      setModalTransferirChamado({ id_departamento: "", id_usuario: "", id_chamado: "" });
      await Swal.fire({ icon: "success", title: "O atendimento foi transferido com sucesso!" });
    }
  };

  const finalizarAtendimento = async id_chamado => {
    const { ok } = await WebService(`/chamado/finalizarChamado`, { id_chamado, flag_silencioso: true });
    if (ok) {
      await Swal.fire({ title: "Atendimento finalizado com sucesso!", icon: "success" });
      await getChamados();
    }
  };

  const getMensagensChamado = async id_chamado => {
    // const { ok, mensagens } = await WebService(`/chamado/getChamadoConversas`, { id_chamado });
    // if (ok) setHistoricoChamado({ ...historicoChamado, id_chamado, mensagens });
    setHistoricoChamado({ ...historicoChamado, id_chamado, mensagens: [] });
  };

  const [modalBulkActionsOpen, setModalBulkActionsOpen] = useState(false);

  const bulkActions = async type => {
    const {value} = await Swal.fire({
      icon: "warning",
      title: 'Deseja realmente executar essa ação?',
      showCancelButton: true,
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim'
    });
    if (!value) return;

    for (let i = 0; i < chamados.length; i += 1) {
      const {id_chamado, status} = chamados[i];
      if (type === 'finish') {
        if (status === 'atendimento_finalizado') continue;
        await WebService('/chamado/finalizarChamado', {id_chamado, flag_silencioso: true});
        // v1/chamado/finalizarChamado?id_chamado=1&flag_silencioso=true
      }
    }
    setModalBulkActionsOpen(false);
  };

  const bulkEndCalleds = async () => {

  }

  const exportReport = async () => {
    const {value} = await Swal.fire({
      icon: "info",
      title: 'Revisar configuração do relatório.',
      html: 'O relatório exportado irá conter as informações da busca atual.<br/><br/>Verifique as datas de início e final e outros parâmetros do filtro e realize a busca antes de exportar.<br/><br/>Verifique também se alistagem abaixo contém as informações que deseja.',
      showCancelButton: true,
      cancelButtonText: 'Voltar',
      confirmButtonText: 'Sim, os filtros estão corretos!'
    });
    if (!value) return;
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset-UTF-8';

    const ws = XLSX.utils.json_to_sheet(chamados.map(prop => {
      let { flag_finalizado, status, } = prop;
      status = flag_finalizado ? 'atendimento_finalizado' : status;

      const report = {};
      report['Protocolo'] = prop['id_chamado'];
      report['Nome'] = prop['nome'];
      report['Número'] = prop['numero'];
      report['Departamento'] = prop['departamento'];
      report['Atendente'] = prop['atendente'];
      report['Status'] = status;
      report['Data de Início'] = convertDate(prop['data'], false, true);
      report['Data de Finalização'] = convertDate(prop['data_finalizado'], false, true);
      report['Resumo do atendimento'] = prop['resume'];
      report['Tags'] = (prop['tags'] || []).map(prop => prop.name).join('|');

      return report;
    }));
    const wb = { Sheets: { 'data': ws }, SheetNames: [ 'data' ] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([ excelBuffer ], { type: fileType });
    FileSaver.saveAs(data, `Relatório Atendimentos.xlsx`);
  };

  useEffect(() => {
    getDepartamentos();
    getUsuarios();
    getConexoes();
    getChamados();
  }, []);

  useEffect(() => {
    if (protocolo && showNotice) {
      Swal.fire({
        icon: "info",
        title: 'A pesquisa por protocolo fará o sistema ignorar os demais filtros.',
        text: 'Para voltar a pesquisar pelos outros parâmetros, apague o campo de procolo completamente.',
        allowOutsideClick: false,
        confirmButtonText: 'Continuar'
      });
      showNotice = false;
    }
  }, [protocolo]);

  return (
    <>
      <ModalCalledHistory
        open={!!historicoChamado.id_chamado}
        id={historicoChamado.id_chamado}
        onClose={() => setHistoricoChamado({atendente: "", id_chamado: "", mensagens: []})}
      />
      <Modal onClose={() => setModalTransferirChamado({ id_chamado: "", id_departamento: "", id_usuario: "" })} open={!!modalTransferirChamado.id_chamado} className={classes.modal}>
        <>
          <Card className={classes.cardUser}>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <SyncAlt />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Transferir Atendimento</h4>

              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={() => setModalTransferirChamado({ id_chamado: "", id_departamento: "", id_usuario: "" })}
              >
                <Close />
              </IconButton>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem lg={12} md={12} sm={12} xs={12}>
                  <FormControl className={classes.formControl} fullWidth>
                    <InputLabel>Departamento</InputLabel>
                    <Select
                      value={modalTransferirChamado.id_departamento}
                      onChange={({ target: { value } }) => setModalTransferirChamado({
                        ...modalTransferirChamado,
                        id_departamento: value
                      })}
                      style={{ textAlign: 'left' }}
                    >
                      {departamentos.map(prop => {
                        const { nome, id_departamento } = prop;
                        return (<MenuItem value={id_departamento} key={`dpt_${id_departamento}`}>{nome}</MenuItem>);
                      })}
                    </Select>
                  </FormControl>
                </GridItem>
                <p>&nbsp;</p>
                {!!modalTransferirChamado.id_departamento && (
                  <GridItem lg={12} md={12} sm={12} xs={12}>
                    <FormControl className={classes.formControl} fullWidth>
                      <InputLabel>Usuário</InputLabel>
                      <Select
                        value={modalTransferirChamado.id_usuario}
                        onChange={({ target: { value } }) => setModalTransferirChamado({
                          ...modalTransferirChamado,
                          id_usuario: value
                        })}
                        style={{ textAlign: 'left' }}
                      >
                        {usuarios.filter(el => el.id_departamento_fk && !el.me && el.id_departamento_fk.split(",").map(prop => parseInt(prop)).indexOf(modalTransferirChamado.id_departamento) > -1).map(prop => {
                          const { nome, id_usuario } = prop;
                          const sort = [...usuarios].sort((a, b) => a.nome.localeCompare(b.nome));
                          console.log(sort);

                          return (<MenuItem value={id_usuario} key={`usr_${id_usuario}`}>{nome}</MenuItem>);
                        })}
                      </Select>
                    </FormControl>
                  </GridItem>
                )}
              </GridContainer>
            </CardBody>
            <CardFooter className={classes.cardFooter} stats>
              <CustomButtons onClick={() => setModalTransferirChamado({ id_chamado: "", id_departamento: "", id_usuario: "" })}>Cancelar</CustomButtons>
              <CustomButtons color="warning" onClick={transferirAtendimento.bind(this)} disabled={!modalTransferirChamado.id_departamento}>Transferir</CustomButtons>
            </CardFooter>
          </Card>
        </>
      </Modal>
      <ModalBulkActionsReportCalleds
        open={modalBulkActionsOpen}
        onClose={() => {
          setModalBulkActionsOpen(false);
          getChamados();
        }}
        calleds={chamados}
        users={usuarios}
        departments={departamentos}
        connections={conexoes}
      />
      <GridContainer>
        {/*<HistoricoConversa*/}
        {/*  open={historicoChamado.id_chamado}*/}
        {/*  id_chamado={historicoChamado.id_chamado}*/}
        {/*  mensagens={historicoChamado.mensagens}*/}
        {/*  onClose={() => setHistoricoChamado({ atendente: "", id_chamado: "", mensagens: [] })}*/}
        {/*/>*/}
        <GridItem lg={12} md={12} sm={12} xs={12}>
          <GridContainer style={{ display: 'flex' }}>
            <GridItem lg={3} md={3} sm={6} xs={12} style={{ display: 'inline-grid' }}>
              <Card>
                <CardHeader color={"primary"} stats icon>
                  <CardIcon color={"primary"} style={{ width: 85, height: 85 }}>
                    <Business />
                  </CardIcon>
                  <p></p>
                  <h2 className={classes.cardTitle}>
                    <small>Histórico de atendimentos</small>
                  </h2>
                  <p className={classes.cardCategory}>
                    <h3><small>{chamados.length}</small></h3>
                  </p>
                  <h4 className={classes.cardTitle}>
                    <small>Total de atendimentos</small>
                  </h4>
                  <p>&nbsp;</p>
                </CardHeader>
              </Card>
            </GridItem>
            <GridItem lg={9} md={9} sm={12} xs={12} style={{ display: 'inline-grid' }}>
              <Card>
                <CardBody>
                  <GridContainer>
                    <GridItem lg={3} md={3} sm={4} xs={6}>
                      <FormControl className={classes.formControl} fullWidth>
                        <InputLabel>Status</InputLabel>
                        <Select
                          value={status}
                          onChange={({ target: { value } }) => setStatus(value)}
                          style={{ textAlign: 'left' }}
                          disabled={!!protocolo}
                        >
                          <MenuItem value={""}>Todos</MenuItem>
                          {Object.keys(statusLabel).map(prop => (
                            <MenuItem key={prop} value={prop}>{statusLabel[prop]}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem lg={3} md={3} sm={4} xs={6}>
                      <CustomInput
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          placeholder: "Protocolo",
                          onChange: ({ target: { value } }) => setProtocolo(value),
                          // value: protocolo
                        }}
                      />
                    </GridItem>
                    <GridItem lg={3} md={3} sm={4} xs={6}>
                      <CustomInput
                        labelText="Nome do cliente"
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          // onChange: ({target: {value}}) => auxSetFiltro('nomeCliente', value),
                          onChange: ({ target: { value } }) => setNomeCliente(value),
                          disabled: !!protocolo,
                          // value: nomeCliente
                        }}
                      />
                    </GridItem>
                    <GridItem lg={3} md={3} sm={4} xs={6}>
                      <CustomInput
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          placeholder: "Número telefone",
                          onChange: ({ target: { value } }) => setTelefone(value),
                          // value: telefone
                          disabled: !!protocolo,
                        }}
                      />
                    </GridItem>
                    <GridItem lg={3} md={3} sm={4} xs={6}>
                      <FormControl className={classes.formControl} fullWidth>
                        <InputLabel>Conexão</InputLabel>
                        <Select
                          value={conexao}
                          onChange={({ target: { value } }) => setConexao(value)}
                          style={{ textAlign: 'left' }}
                          disabled={!!protocolo}
                        >
                          <MenuItem value={""}>Todos</MenuItem>
                          {/*flag_ativo*/}
                          {conexoes.map(prop => (
                            <MenuItem
                              key={prop.id_whatsapp_conexao}
                              value={prop.id_whatsapp_conexao}
                            >
                              <ListItemText primary={prop.conta_nome} secondary={prop.conta_numero} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                    {/******************************************************************************************************/}
                    <GridItem lg={3} md={3} sm={4} xs={6}>
                      <FormControl className={classes.formControl} fullWidth>
                        <InputLabel>Departamento</InputLabel>
                        <Select
                          value={departamento}
                          onChange={({ target: { value } }) => setDepartamento(value)}
                          style={{ textAlign: 'left' }}
                          disabled={!!protocolo}
                        >
                          <MenuItem value={""}>Todos</MenuItem>
                          {departamentos.map(prop => (
                            <MenuItem key={prop.id_departamento} value={prop.id_departamento}>{prop.nome}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>

                    <GridItem lg={3} md={3} sm={4} xs={6}>
                      <FormControl className={classes.formControl} fullWidth>
                        <InputLabel>Atendente</InputLabel>
                        <Select
                          value={atendente}
                          onChange={({ target: { value } }) => setAtendente(value)}
                          style={{ textAlign: 'left' }}
                          disabled={!!protocolo}
                        >
                          <MenuItem value={""}>Todos</MenuItem>
                          {usuarios.map(prop => (
                            <MenuItem key={prop.id_usuario} value={prop.id_usuario}>{prop.nome}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>

                    <GridItem lg={3} md={3} sm={4} xs={6}>
                      <FormControl className={classes.formControl} fullWidth>
                        <InputLabel>Tag</InputLabel>
                        <Select
                          value={tags}
                          onChange={({ target: { value } }) => {
                            if (value.includes('')) setTags([]);
                            else setTags(value);
                          }}
                          style={{ textAlign: 'left' }}
                          multiple
                          disabled={!!protocolo}
                          // value={dept}
                          // onChange={handleChangeDept}
                          // input={<Input />}
                          // renderValue={(selected) => selected.join(', ')}
                        >
                          <MenuItem value={""}>Todos</MenuItem>
                          {tagSystem.map(prop => (
                            <MenuItem key={prop.id} value={prop.id}>{prop.name}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem lg={12} md={12} sm={12} xs={12}>
                      <hr />
                    </GridItem>
                    <GridItem lg={3} md={3} sm={4} xs={6}>
                      <InputLabel className={classes.label} style={{ marginBottom: -10 }}>
                        Período Inicial
                    </InputLabel>
                      <br />
                      <FormControl fullWidth>
                        <Datetime
                          timeFormat={false}
                          onChange={e => typeof e === 'object' ? setPeriodoInicial(e.toDate()) : (e.length === 10 ? (isValidDate(new Date(e.split("/").reverse().join("-"))) ? setPeriodoInicial(new Date(e.split("/").reverse().join("-"))) : "") : "")}
                          // value={periodoInicial}
                          initialValue={periodoInicial}
                          inputProps={{ placeholder: "Período Inicial", id: 'periodo-inicial', disabled: !!protocolo }}
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem lg={3} md={3} sm={4} xs={6}>
                      <InputLabel className={classes.label} style={{ marginBottom: -10 }}>
                        Período Final
                      </InputLabel>
                      <br />
                      <FormControl fullWidth>
                        <Datetime
                          timeFormat={false}
                          onChange={e => typeof e === 'object' ? setPeriodoFinal(e.toDate()) : (e.length === 10 ? (isValidDate(new Date(e.split("/").reverse().join("-"))) ? setPeriodoFinal(new Date(e.split("/").reverse().join("-"))) : "") : "")}
                          // value={periodoFinal}
                          initialValue={periodoFinal}
                          inputProps={{ placeholder: "Período Final", id: 'periodo-final', disabled: !!protocolo }}
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem lg={3} md={3} sm={4} xs={6}>
                      {/*dateFilterType*/}
                      {[
                        { name: 'Data de abertura', slug: 'start' },
                        { name: 'Data de encerramento', slug: 'end' },
                        { name: 'Ultima mensagem', slug: 'message' },
                      ].map(prop => {
                        return (
                          <FormControlLabel
                            classes={{ root: classes.checkboxLabelControl, label: classes.checkboxLabel }}
                            control={
                              <Checkbox
                                tabIndex={-1}
                                onClick={() => setDateFilterType(prop.slug)}
                                checked={dateFilterType === prop.slug}
                                checkedIcon={<Check className={classes.checkedIcon}/>}
                                icon={<Check className={classes.uncheckedIcon}/>}
                                classes={{ checked: classes.checked, root: classes.checkRoot }}
                                style={{padding: 2}}
                              />
                            }
                            label={prop.name}
                          />
                        );
                      })}
                    </GridItem>
                    <GridItem lg={3} md={3} sm={4} xs={6}>
                      <Button color={"hust"} fullWidth onClick={getChamados.bind(this)}>
                        Buscar
                    </Button>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem lg={12} md={12} sm={12} xs={12}>
          <GridContainer justify={'space-between'}>
            <GridItem lg={10} md={10} sm={12} xs={12}>
              <Button color={'hust'} onClick={() => history.push('/relatorios/recusados')}>
                Atendimentos recusados
              </Button>
              <Button color={'hust'} onClick={() => setModalBulkActionsOpen(true)}>
                Açoes em massa
              </Button>
            </GridItem>
            <GridItem lg={2} md={2} sm={4} xs={12} >
              <Button color={'hust'} onClick={exportReport} fullWidth>
                <ImportExport/> Exportar
              </Button>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem lg={12} md={12} sm={12} xs={12}>
          <Card>
            <CardBody>
              {chamados.slice(offset * 20, 20 * (offset + 1)).map(prop => {
                let { id_chamado, flag_finalizado, last_message, status, nome, numero, departamento, data, data_finalizado, foto_perfil, conta_nome, atendente, resume, tags: tagsCalled } = prop;
                status = flag_finalizado ? 'atendimento_finalizado' : status;
                // const finalizadoMesmoDia = ((data.split("-")[0] === data_finalizado.split("-")[0]) && (data.split("-")[1] === data_finalizado.split("-")[1]) && (data.split("T")[0].split("-")[2] === data_finalizado.split("T")[0].split("-")[2]));
                const finalizadoMesmoDia = false;
                const tempoAtendimento = moment(new Date(data)).from(new Date(data_finalizado || new Date())).split('há ').join("").split('em ').join("");
                const ultimaMensagem = moment(new Date()).from(new Date(flag_finalizado ? data_finalizado : (last_message || data))).split('há ').join("").split('em ').join("");

                const InfoAtendimento = ({ data }) => (
                  <table style={{ textAlign: 'left' }}>
                    {data.map((prop, key) => (
                      <tr key={key}>
                        {prop['linha'] ? (
                          <td colSpan={2}>
                            <div style={{width: '100%', display: 'flex'}}>
                              {prop['linha']}
                            </div>
                          </td>
                        ) : (
                          <>
                            <th>{prop['titulo']}:</th>
                            <td>{prop['valor']}</td>
                          </>
                        )}
                      </tr>
                    ))}
                  </table>
                );

                return (
                  <GridContainer
                    style={{
                      display: 'flex',
                      marginBottom: 5,
                      marginTop: 5,
                      backgroundColor: `rgba(${hexToRgb(grayColor[0])}, 0.1)`,
                      padding: 10,
                      borderRadius: 10,
                      color: '#444'
                    }}
                    key={`atendimento_${id_chamado}`}
                  >
                    <GridItem lg={2} md={2} sm={4} xs={12}>
                      <Button
                        disabled
                        color={flag_finalizado ? "" : ((status === "atendimento_pendente") ? "warning" : (['avaliacao_atendimento', 'escolha_departamento'].includes(status) ? "info" : "success"))}
                        fullWidth
                      >
                        {statusLabel[status]}
                      </Button>
                    </GridItem>
                    <GridItem lg={2} md={2} sm={4} xs={12}>
                      <Badge
                        badgeContent={<img
                          className={classes.badgeMensageiro}
                          src={iconMessage('whatsapp')}
                          alt={'whatsapp'}
                        />}
                        style={{ float: 'left' }}
                      >
                        <img alt={nome} src={getFotoLink(foto_perfil)} className={classes.chatListProfilePicture} />
                      </Badge>
                      <div style={{ float: 'left' }}>
                        <p><strong>{nome}</strong></p>
                        <p>{numero}</p>
                      </div>
                    </GridItem>
                    <GridItem lg={2} md={2} sm={4} xs={12}>
                      <InfoAtendimento
                        data={[
                          { titulo: 'Inicio', valor: convertDate(data, false, true, finalizadoMesmoDia) },
                          { titulo: 'Fim', valor: convertDate(data_finalizado, false, true, finalizadoMesmoDia) }
                        ]}
                      />
                    </GridItem>
                    <GridItem lg={2} md={2} sm={4} xs={12}>
                      <InfoAtendimento
                        data={[
                          { titulo: 'Tempo Atend.', valor: tempoAtendimento },
                          !flag_finalizado ? { titulo: 'Ultm. Mensag.', valor: ultimaMensagem } : undefined,
                          { titulo: 'Protocolo', valor: id_chamado }
                        ].filter(el => el)}
                      />
                    </GridItem>
                    <GridItem lg={2} md={2} sm={4} xs={12}>
                      <InfoAtendimento
                        data={[
                          { titulo: 'Departamento', valor: departamento },
                          { titulo: 'Conexão', valor: conta_nome }
                        ]}
                      />
                    </GridItem>
                    <GridItem lg={2} md={2} sm={4} xs={12}>
                      <InfoAtendimento
                        data={[
                          { titulo: 'Atendente', valor: atendente },
                          {
                            linha: (
                              <>
                                {/*<Tooltip title={"Iniciar outro atendimento"}>
                                <Button
                                  color={"success"}
                                  justIcon
                                  round
                                  simple
                                  style={{float: 'right'}}
                                >
                                  <AddIcCall/>
                                </Button>
                          </Tooltip>*/}
                                {!flag_finalizado && (
                                  <>
                                    <Tooltip title={"Finalizar atendimento"}>
                                      <Button
                                        color="danger"
                                        justIcon
                                        round
                                        simple
                                        style={{ float: 'right' }}
                                        onClick={() => finalizarAtendimento(id_chamado)}
                                      >
                                        <PhoneDisabledTwoTone />
                                      </Button>
                                    </Tooltip>
                                    <Tooltip title={"Transferir atendimento"}>
                                      <Button
                                        color={"warning"}
                                        justIcon
                                        round
                                        simple
                                        style={{ float: 'right' }}
                                        onClick={() => setModalTransferirChamado({
                                          ...modalTransferirChamado,
                                          id_chamado: id_chamado
                                        })}
                                      >
                                        <SyncAlt />
                                      </Button>
                                    </Tooltip>
                                  </>
                                )}
                                {resume ? (
                                  <Tooltip title={"Ver resumo"}>
                                    <Button
                                      color={"dribbble"}
                                      justIcon
                                      round
                                      simple
                                      style={{ float: 'right' }}
                                      onClick={() => Swal.fire({
                                        text: resume,
                                        showConfirmButton: false,
                                        cancelButtonText: 'Fechar',
                                        showCancelButton: true
                                      })}
                                    >
                                      <FindInPage />
                                    </Button>
                                  </Tooltip>
                                ): ''}
                                <Tooltip title={"Visualizar chat"}>
                                  <Button
                                    color={"info"}
                                    justIcon
                                    round
                                    simple
                                    style={{ float: 'right' }}
                                    onClick={() => getMensagensChamado(id_chamado)}
                                  >
                                    <Chat />
                                  </Button>
                                </Tooltip>
                              </>
                            )
                          }
                        ]}
                      />
                    </GridItem>
                    <GridItem lg={12} md={12} sm={12} xs={12} style={{marginTop: -20}}>
                      <ReactTags
                        allowNew
                        newTagText={'Criar tag:'}
                        removeButtonText={'Excluir essa tag'}
                        placeholderText={'Tags'}
                        // ref={reactTags}
                        minQueryLength={0}
                        tags={tagsCalled}
                        suggestions={tagSystem}
                        onDelete={async tagKey => {
                          const tag = tagsCalled[tagKey];
                          const {value} = await Swal.fire({
                            icon: 'question',
                            html: `Deseja excluir a tag <strong>${tag.name}</strong> do atendimento <strong>${id_chamado}</strong>?`,
                            showCancelButton: true,
                            cancelButtonText: 'Não',
                            confirmButtonText: 'Sim'
                          });
                          if (!value) return;
                          const {data} = await api.delete(`/called/${id_chamado}/tag`, {params: {id: tag.id}});
                          setChamados(calleds => {
                            const key = calleds.map(prop => prop.id_chamado).indexOf(id_chamado);
                            calleds[key].tags = data;
                            return [...calleds];
                          });
                        }}
                        onAddition={async ({name}) => {
                          const {data: tag} = await api.post('/tag', {name});
                          await getTags();
                          const {data: tags} = await api.put(`/called/${id_chamado}/tag`, {idTag: tag.id});
                          setChamados(calleds => {
                            const key = calleds.map(prop => prop.id_chamado).indexOf(id_chamado);
                            calleds[key].tags = tags;
                            return [...calleds];
                          });
                        }}
                      />
                    </GridItem>
                    {/*<GridItem lg={2} md={2} sm={4} xs={12}>

                  </GridItem>*/}
                  </GridContainer>
                );
              })}
              {chamados.length > 10 && (
                <GridContainer justify={"center"}>
                  <GridItem lg={2} md={3} sm={4} xs={5}>
                    <Button color={"info"} onClick={() => setOffset(o => o - 1)} fullWidth disabled={!offset}>
                      Voltar
                  </Button>
                  </GridItem>
                  <GridItem lg={1} md={1} sm={1} xs={1} align={"center"}>
                    <br />
                    <p style={{ marginTop: -7 }}>{offset + 1}</p>
                  </GridItem>
                  <GridItem lg={2} md={3} sm={4} xs={5}>
                    <Button
                      color={"info"}
                      onClick={() => setOffset(o => o + 1)}
                      fullWidth
                      disabled={offset + 1 > (chamados.length / 20)}
                    >
                      Avançar
                  </Button>
                  </GridItem>
                </GridContainer>
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}
