import React, { memo, useEffect, useState } from 'react';
import { Handle, Position } from 'reactflow';
import { Card, CardBody } from "../../../components";
import { WebService } from "../../../components/WebService";
import Header from "./components/Header";
import { Ticket } from "../../../assets/img/icons";
import Checkbox from "./components/Input/Checkbox";
import TextBox from "./components/Input/TextBox";
import Select from "./components/Input/Select";
import RemoveNode from "../components/RemoveNode";

function Start({ data, selected, id }) {
  const [ departments, setDepartments ] = useState([]);

  const getDepartments = async () => {
    const {ok, departamentos} = await WebService(`/departamento/getDepartamentos`);
    if (ok) {
      const tempDepartamentos = [];
      const getChild = (d, n) => {
        d.map(prop => {
          const {nome, id_departamento, subDepartamentos} = prop;
          if (subDepartamentos) getChild(subDepartamentos, `${n ? n + ' -> ' : ''}${nome}`);
          else tempDepartamentos.push({name: `${n ? n + ' -> ' : ''}${nome}`, id: id_departamento});
        });
      };
      getChild(departamentos);
      setDepartments([...tempDepartamentos].sort((a, b) => a.name.localeCompare(b.name)));
    }
  };

  const [ enabled, setEnabled ] = useState(!!data.enabled);
  const [ callerType, setCallerType ] = useState(data.callerType || '');
  const [ department, setDepartment ] = useState(data.department || '');
  const [ word, setWord ] = useState(data.word || '');
  const [ name, setName ] = useState(data.name || '');
  // * * * * * *
  const [ executeInWait, setExecuteInWait ] = useState(!!data.executeInWait);
  const [ executeInAttendance, setExecuteInAttendance ] = useState(!!data.executeInAttendance);
  const [ repeat, setRepeat ] = useState(!!data.repeat);
  const [ abortExecutionTransference, setAbortExecutionTransference ] = useState(!!data.abortExecutionTransference);

  useEffect(() => {
    data.enabled = enabled;
    data.department = department;
    data.word = word;
    data.name = name;

    data.executeInWait = executeInWait;
    data.executeInAttendance = executeInAttendance;
    data.repeat = repeat;
    data.abortExecutionTransference = abortExecutionTransference;
  }, [ enabled, department, word, name, executeInWait, executeInAttendance, repeat, abortExecutionTransference ]);

  useEffect(() => {
    if (data.callerType !== callerType) {
      data.callerType = callerType;
      setDepartment('');
      setWord('');
    }
    if (callerType === 'Campaign') setEnabled(true);
  }, [callerType]);

  useEffect(() => {
    getDepartments();
    // console.log(data.callerType);
  }, []);

  return (
    <Card
      style={{
        margin: 0,
        border: '1px solid #FF3115',
        width: 350,
        background: enabled ? '#FFF' : `#FFEEEE`,
        fontWeight: 'bold',
        borderRadius: 14,
        boxShadow: selected ? '0px 50px 50px 0px rgba(4, 15, 21, 0.15), 0px 40px 30px -30px rgba(4, 15, 21, 0.15)' : '#08365A 0px 0px 20px -10px',
        padding: 5
      }}
    >
      <RemoveNode id={id}/>
      <Header icon={Ticket} title={'Iniciar BOT'} subtitle={'Define fluxo de regras'}/>
      <CardBody style={{ padding: 5 }}>
        {![ 'Campaign' ].includes(callerType) && (
          <Checkbox label={'BOT ativo'} checked={enabled} onClick={() => setEnabled(!enabled)}/>
        )}
        <TextBox label={'Nome do BOT'} value={name} onChange={setName}/>
        <Select
          label={'Acionar'}
          value={callerType}
          onChange={setCallerType}
          options={[
            { name: 'Selecione um tipo', value: '', disabled: true },
            { name: 'Departamento', value: 'WaitInDepartment', disabled: false },
            { name: 'Palavra', value: 'Word', disabled: false },
            { name: 'Campanha', value: 'Campaign', disabled: false },
            // { name: 'Atendimento - Ao entrar na fila de espera', value: 'Campaign', disabled: false },
            // { name: 'Atendimento - Ao Finalizar chamado', value: 'Campaign', disabled: false },
          ]}
        />
        {enabled && (
          <>
            {callerType === 'WaitInDepartment' && (
              <Select
                label={'Departamento'}
                value={department}
                onChange={setDepartment}
                options={[
                  { name: 'Selecione o departamento', value: '', disabled: true },
                  ...departments.map(prop => ({ name: prop.name, value: prop.id }))
                ]}
              />
            )}
            {callerType === 'Word' && (
              <TextBox label={'Palavra de acionamento'} value={word} onChange={setWord}/>
            )}
            {['Word', 'Campaign', 'WaitInDepartment'].includes(callerType) && (
              <Checkbox label={'Repetição do BOT no mesmo protocolo'} checked={repeat} onClick={() => setRepeat(!repeat)}/>
            )}
            {/*{['Word', 'Campaign'].includes(callerType) && (
              <Checkbox label={'Executar na fila de espera'} checked={executeInWait} onClick={() => setExecuteInWait(!executeInWait)}/>
            )}
            {['Word', 'Campaign'].includes(callerType) && (
              <Checkbox label={'Executar em atendimento'} checked={executeInAttendance} onClick={() => setExecuteInAttendance(!executeInAttendance)}/>)}
            {['WaitInDepartment', 'Word', 'Campaign'].includes(callerType) && (
              <Checkbox label={'Parar execução ao mudar status ou depart.'} checked={abortExecutionTransference} onClick={() => setAbortExecutionTransference(!abortExecutionTransference)}/>
            )}*/}
          </>
        )}
      </CardBody>
      <Handle type="source" position={Position.Right} style={{ marginRight: -5, background: '#FFFFFF', width: 16, border: '2px solid #E94D3F', height: 16 }}/>
    </Card>
  );
}

export default memo(Start);
