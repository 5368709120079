import React, {memo, useEffect, useState} from 'react';
import {Handle, Position} from 'reactflow';
import {Card, CardBody} from "../../../components";
import {AgentAi as AgentAiIcon} from "../../../assets/img/icons";
import {WebService} from "../../../components/WebService";
import Header from "./components/Header";
import Checkbox from "./components/Input/Checkbox";
import TextBox from "./components/Input/TextBox";
import Select from "./components/Input/Select";
import RemoveNode from "../components/RemoveNode";
import axios from "axios";

function AgentAi({data, selected, id}) {
  const [agents, setAgents] = useState([]);

  const [agent, setAgent] = useState(data.agent || '');
  const [name, setName] = useState(data.name || '');

  useEffect(() => {
    data.name = name;
    data.agent = agent;
  }, [agent, name]);

  const getAgents = async () => {
    const {data}= await axios.get(`https://workflow.hustapp.com/webhook/1f3b1058-86d2-494f-b20a-e7247727ad8a`);
    setAgents(data);
  };
  useEffect(() => {
    getAgents();
    if (!name) setName('Mari.IA');
    // console.log(data.callerType);
  }, []);

  return (
    <Card
      style={{
        margin: 0,
        border: '1px solid #FF3115',
        width: 350,
        background: '#FFF',
        fontWeight: 'bold',
        borderRadius: 14,
        boxShadow: selected ? '0px 50px 50px 0px rgba(4, 15, 21, 0.15), 0px 40px 30px -30px rgba(4, 15, 21, 0.15)' : '#08365A 0px 0px 20px -10px',
        padding: 5
      }}
    >
      <RemoveNode id={id}/>
      <Header icon={AgentAiIcon} title={'Agente de IA'} subtitle={'Processa as mensagens com IA'}/>
      <CardBody style={{padding: 5}}>
        <TextBox label={'Nome do Agente'} value={name} onChange={setName}/>
        <Select
          label={'Acionar'}
          value={agent}
          onChange={setAgent}
          options={[
            { name: 'Selecione um agente', value: '', disabled: true },
            ...agents.map(prop => ({name: prop.name, value: prop.slug, disabled: false}))
          ]}
        />
      </CardBody>
      <Handle type="target" position={Position.Left} style={{ marginLeft: -3, background: '#E94D3F', width: 16, border: '3px solid #FFFFFF', height: 16 }}/>
    </Card>
  );
}

export default memo(AgentAi);
