import React, {useEffect} from 'react';
import DashboardIcon from "@material-ui/icons/Dashboard";
import ListarConexoes from "./views/ListarConexoes";
import Build from "@material-ui/icons/Build";
import Configuracoes from "./views/Configuracoes";
import LoginPage from "./views/LoginPage";
import AddToHomeScreen from "@material-ui/icons/AddToHomeScreen";
import TagIcon from "@material-ui/icons/Label";
import Dashboard from "./views/Dashboard";
import BotConfiguracao from "./views/BotConfiguracao";
import RelatorioAtendimento from "./views/Relatorios/RelatorioAtendimento";
import DepartamentoManager from "./views/Departamento/DepartamentoManager";
import {ls, sairSistema} from "./components/Funcoes";
import ShopTwo from "@material-ui/icons/ShopTwo";
import BusinessCenter from "@material-ui/icons/BusinessCenter";
import Chat from "@material-ui/icons/Chat";
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import Description from "@material-ui/icons/Description";
import Person from "@material-ui/icons/Person";
import Settings from "@material-ui/icons/Settings";
import Contacts from "@material-ui/icons/Contacts";
import AssignmentInd from "@material-ui/icons/AssignmentInd";
import QuestionAnswer from "@material-ui/icons/QuestionAnswer";
import ExitToApp from '@material-ui/icons/ExitToApp';
import NewDash from "./views/NewDash";
import NewChat from "./views/NewChat/Chat";
import Clientes from "./views/Clientes/ManagerClientes";
import ManagerRevenda from "./views/Revenda/ManagerRevenda";
import Utilizadores from "./views/Usuarios/Utilizadores/Utilizadores";
import Contatos from "./views/Usuarios/Contatos/Contatos";

import DashboardDisparo from "./views/Envios/Dashboard";

//Configs
import RespostasRapidas from './views/Configs/RespostasRapidas/RespostasRapidas';
import AssuntoChamado from './views/Configs/AssuntoDoChamado/AssuntoDoChamado';
import { Business, CompareArrows, MoneyOff, SpeakerNotes, Star } from '@material-ui/icons';
import ChangeLogs from "./views/ChangeLogs";
import BetaControll, {checkBeta} from "./views/BetaControll";
import RealTime from "./views/Dashboard/RealTime";
import Financeiro from "./views/Financeiro";
import BotNovo from "./views/BOT/BotNovo";
import AtendimentosRecusados from "./views/Relatorios/AtendimentosRecusados";
import AvaliacaoAtendimento from "./views/Avaliacao/AvaliacaoAtendimento";
import Tags from "./views/Configs/Tags";
import SendIcon from "@material-ui/icons/Send";
import SendingList from "./views/Envios/SendingList";
import SendingListEdit from "./views/Envios/SendingListEdit";
import Campaign from "./views/Envios/Campaign";
import CampaignEdit from "./views/Envios/CampaignEdit";
import ContactsImport from "./views/ContactsImport";
import ListConnections from "./views/Connection/ListConnections";
import Configurations from "./views/Configurations";
import ManagerClient from "./views/Admin/ManagerClient";
import ClientConfiguration from "./views/Admin/ClientConfiguration";
import RegisterPage from "./views/RegisterPage";
import NameSideBarSubtitle from './components/NameSidebarSubtitle';
import TaskManager from "./views/Tasks/TaskManager";
import {FaTasks} from "react-icons/fa";
import { isPartner } from 'utils/validateIsPartner';
import Pipeline from "./views/Tasks/Pipeline";
import CreateNewConnection from "./views/partner/CreateNewConnection";
import MensageBuilder from "./components/MensageBuilder/MensageBuilder";

const dashRoutes = [];

dashRoutes.push({
  path: "/dashboard",
  name: "Dashboard",
  icon: DashboardIcon,
  component: NewDash,
  layout: "/admin"
});
dashRoutes.push({
  path: "/dashboard-realtime",
  name: "Dashboard",
  hide: true,
  icon: DashboardIcon,
  component: RealTime,
  layout: "/admin"
});

dashRoutes.push({
  path: "/chat",
  name: "Chat",
  icon: Chat,
  allUsers: true,
  component: NewChat,
  layout: "/admin",
});

dashRoutes.push({
  path: "/conexoes/configuracoes",
  name: "Configurações",
  icon: Build,
  hide: true,
  component: Configuracoes,
  layout: "/admin"
});

dashRoutes.push({
  path: "/conexoes",
  name: "Conexões",
  icon: AddToHomeScreen,
  component: checkBeta('new-connection-page') ? ListConnections : ListarConexoes,
  layout: "/admin"
});

dashRoutes.push({
  path: "/relatorios/recusados",
  name: "Relatórios",
  icon: Description,
  component: AtendimentosRecusados,
  layout: "/admin",
  hide: true
});

dashRoutes.push({
  path: "/relatorios",
  name: "Relatórios",
  icon: Description,
  component: RelatorioAtendimento,
  layout: "/admin"
});

dashRoutes.push({
  path: "/bot",
  name: "Bot",
  icon: Build,
  component: BotConfiguracao,
  // component: BotNovo,
  layout: "/admin"
});

dashRoutes.push({
  path: "/bot_novo",
  name: "Bot",
  hide: true,
  icon: Build,
  // component: BotConfiguracao,
  component: BotNovo,
  layout: "/admin"
});

dashRoutes.push({
  path: "/departamentos",
  name: "Departamentos",
  icon: BusinessCenter,
  component: DepartamentoManager,
  layout: "/admin"
});

if (checkBeta('task-manager')) {
  dashRoutes.push({
    path: "/crm",
    name: "CRM",
    icon: FaTasks,
    component: Pipeline,
    layout: "/admin"
  });
}

dashRoutes.push({
  path: "/avaliacao",
  name: "Avaliação atendimento",
  icon: Star,
  component: AvaliacaoAtendimento,
  layout: "/admin"
});

// ****************************************** Rotas da ferramenta de disparto ******************************************
if (checkBeta('hust-envios')) {
  dashRoutes.push({
    path: "/bulkSend/sendingList/:id",
    name: 'Editar - Lista de envio',
    hide: true,
    component: SendingListEdit,
    layout: "/admin"
  });

  dashRoutes.push({
    path: "/bulkSend/sendingList",
    name: 'Lista de envio',
    hide: true,
    component: SendingList,
    layout: "/admin"
  });

  dashRoutes.push({
    path: "/bulkSend/campaign/:id",
    name: 'Editar - Campanha',
    hide: true,
    component: CampaignEdit,
    layout: "/admin"
  });

  dashRoutes.push({
    path: "/bulkSend/campaign",
    name: 'Campanha',
    hide: true,
    component: Campaign,
    layout: "/admin"
  });

  dashRoutes.push({
    path: "/bulkSend",
    name: `${isPartner ? '' : 'Hust'} Envios`,
    icon: SendIcon,
    component: DashboardDisparo,
    layout: "/admin"
  });
} else {
  dashRoutes.push({
    path: "/bulkSend",
    name: <NameSideBarSubtitle name='Hust Envios' subtitle="Novidade!" />,
    icon: SendIcon,
    component: DashboardDisparo,
    layout: "/admin"
  });
}

// dashRoutes.push({
//   path: "/usuarios/:id",
//   name: "Configurações",
//   icon: Build,
//   hide: true,
//   component: UsuarioSingle,
//   layout: "/admin"
// });


dashRoutes.push({
  path: "/contatos/importacao",
  name: "Importação dos contatos",
  icon: Person,
  component: ContactsImport,
  hide: true,
  layout: "/admin"
});

dashRoutes.push({
  name: "Pessoas",
  icon: Person,
  collapse: true,
  state: 'pessoas',
  views: [
    {
      path: "/usuarios/utilizadores",
      name: "Usuários",
      mini: <ContactPhoneIcon style={{fontSize: '12px', marginLeft: '20px'}}/>,//{<ContactPhoneIcon style={{fontSize: '12px', marginLeft: '20px'}}/>}
      component: Utilizadores,
      layout: "/admin"
    },
    {
      path: "/contatos",
      name: "Contatos",
      mini: <Contacts style={{fontSize: '12px', marginLeft: '20px'}}/>,
      component: Contatos,
      layout: "/admin"
    },
    (!!JSON.parse(ls('cliente_revenda') || false) ? {
      path: "/usuarios/clientes",
      name: "Empresas",
      mini: <AssignmentInd style={{fontSize: '12px', marginLeft: '20px'}}/>,
      component: Clientes,
      revenda: true,
      layout: "/admin"
    } : null)
  ].filter(el => el)
});

dashRoutes.push({
  name: "Configurações",
  icon: Settings,
  state: 'configuracao',
  collapse: true,
  views: [
    {
      path: "/config/respostas_rapidas",
      name: "Respostas Rápidas",
      mini: <QuestionAnswer style={{fontSize: '12px', marginLeft: '20px'}}/>,
      component: RespostasRapidas,
      layout: "/admin"
    },
    /*{
      path: "/config/assunto_chamado",
      name: "Assunto do Chamado",
      mini: <SpeakerNotes style={{fontSize: '12px', marginLeft: '20px'}}/>,
      component: AssuntoChamado,
      layout: "/admin"
    },*/
    {
      path: "/config/tags",
      name: "Tags",
      mini: <TagIcon style={{fontSize: '12px', marginLeft: '20px'}}/>,
      component: Tags,
      layout: "/admin"
    },
    {
      path: "/config/empresa",
      name: "Empresa",
      mini: <Business style={{fontSize: '12px', marginLeft: '20px'}}/>,
      component: Configurations,
      layout: "/admin"
    }
  ]
});

dashRoutes.push({
  path: "/revenda",
  name: "Revenda",
  icon: ShopTwo,
  revenda: true,
  component: ManagerRevenda,
  layout: "/admin"
});

// TODO - Corrigir e atualizar antes de voltar
/* dashRoutes.push({
  path: "/changelog",
  name: "Atualizações do sistema",
  icon: CompareArrows,
  component: ChangeLogs,
  layout: "/admin"
});/**/

dashRoutes.push({
  path: "/financeiro",
  name: "Financeiro",
  icon: MoneyOff,
  component: Financeiro,
  layout: "/admin"
});

dashRoutes.push({
  path: "/sair",
  name: "Sair",
  icon: ExitToApp,
  //hide: true,
  allUsers: true,
  component: () => {
    useEffect(() => sairSistema(), []);
    return '';
  },
  layout: "/admin"
});

dashRoutes.push({
  path: "/beta",
  name: "Beta",
  icon: null,
  hide: true,
  allUsers: true,
  component: BetaControll,
  layout: "/admin"
});

dashRoutes.push({
  path: "/admin/client/:id",
  name: "Admin",
  icon: null,
  hide: true,
  allUsers: false,
  component: ClientConfiguration,
  layout: "/admin"
});

dashRoutes.push({
  path: "/admin",
  name: "Admin",
  icon: null,
  hide: true,
  allUsers: false,
  component: ManagerClient,
  layout: "/admin"
});

/*
dashRoutes.push({
  path: "/envio_massa/novo",
  name: "Envio em massa",
  icon: QuestionAnswer,
  component: EnvioMassa,
  hide: true,
  layout: "/admin"
});

dashRoutes.push({
  path: "/envio_massa/acompanhar",
  name: "Envio em massa",
  icon: QuestionAnswer,
  component: EnvioMassaAcompanhar,
  hide: true,
  layout: "/admin"
});

dashRoutes.push({
  path: "/envio_massa",
  name: "Envio em massa",
  icon: QuestionAnswer,
  component: ListarEnviosMassa,
  layout: "/admin"
});*/

/*if (localStorage.getItem("admin") === "true") {
  dashRoutes.push({
    path: "/monitor",
    name: "Monitorar Instâncias",
    icon: Build,
    component: Monitor,
    layout: "/admin"
  });
}*/

dashRoutes.push({
  path: "/login",
  name: "Login",
  component: LoginPage,
  layout: "/auth",
  hide: true
});

dashRoutes.push({
  path: "/partner/connection",
  name: "Criar Conexão",
  component: CreateNewConnection,
  layout: "/auth",
  hide: true
});

dashRoutes.push({
  path: "/mensagebuilder",
  name: "Pre-visualizador de mensagem",
  component: MensageBuilder,
  layout: "/admin",
  hide: true
});

/*dashRoutes.push({
  path: "/cadastro",
  name: "Login",
  component: RegisterPage,
  layout: "/auth",
  hide: true
});*/

export default dashRoutes;
