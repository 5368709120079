import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import Swal from 'sweetalert2';
import { MessageBox } from 'react-chat-elements';
import 'react-chat-elements/dist/main.css';
import {converterTextoWpp} from "../../components/Funcoes";
import Button from "../../components/CustomButtons/Button";

const ModalEditarMensagem = ({ mensagemOriginal, onClose, onSalvar }) => {
    const [mensagem, setMensagem] = useState(mensagemOriginal);

    const handleSalvar = () => {
        if (mensagem.trim() === '') {
            Swal.fire({
                title: 'Erro',
                text: 'A mensagem não pode estar vazia!',
                icon: 'error',
                confirmButtonColor: '#ff3e3e',
            });
            return;
        }
        onSalvar(mensagem);
    };

    // Estilos
    const styles = {
        overlay: {
            position: 'fixed',
            fontFamily: 'NunitoRegular',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
        },
        modal: {
            backgroundColor: '#fff',
            width: '80%',  // Diminuir a largura do modal
            maxWidth: '500px',  // Largura máxima do modal
            borderRadius: '12px',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
            maxHeight: '80vh',
            padding: '4px'
        },
        header: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '4px',
            borderBottom: '1px solid #eee',
        },
        title: {
            fontSize: '18px',
            fontWeight: 'bold',
            color: '#333',
            margin: 0,
        },
        closeButton: {
            background: 'none',
            border: 'none',
            cursor: 'pointer',
            fontSize: '20px',
            color: '#666',
        },
        content: {
            padding: '4px',
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
            overflowY: 'hidden', // Remove o scroll vertical do conteúdo
        },
        label: {
            fontSize: '14px',
            fontWeight: 'bold',
            color: '#555',
        },
        textarea: {
            width: '100%',
            fontFamily: 'NunitoRegular',
            padding: '8px',
            borderRadius: '8px',
            border: '1px solid #ddd',
            fontSize: '14px',
            outline: 'none',
            resize: 'none',
            minHeight: '80px',
        },
        previewContainer: {
            backgroundColor: '#f0f0f0',
            borderRadius: '8px',
            padding: '4px',
            maxHeight: '150px',  // Diminuir altura da pré-visualização
            overflow: 'hidden',  // Remover scroll
            width: '100%',  // Garantir que ocupe toda a largura
        },
        footer: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: '4px',
            borderTop: '1px solid #eee',
            gap: '8px',
        },
    };

    return (
        <div style={styles.overlay} onClick={(e) => e.target === e.currentTarget && onClose()}>
            <div style={styles.modal}>
                <div style={styles.header}>
                    <h2 style={styles.title}>Editar Mensagem</h2>
                    <Button onClick={onClose} style={{
                        background: 'transparent',
                        border: 'none',
                        color: '#333',
                        fontSize: '24px',
                        cursor: 'pointer',
                        padding: '0',
                        margin: '0',
                        boxShadow: 'none'
                    }}>
                        ×
                    </Button>
                </div>
                <div style={styles.content}>
                    <label style={styles.label}>Mensagem:</label>
                    <textarea
                        value={mensagem}
                        onChange={(e) => setMensagem(e.target.value)}
                        placeholder="Digite sua mensagem..."
                        style={styles.textarea}
                    />
                    <label style={styles.label}>Pré-visualização:</label>
                    <div style={styles.previewContainer}>
                        <MessageBox
                            position={'right'}
                            type={'text'}
                            text={
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: converterTextoWpp(mensagem, true),
                                    }}
                                ></div>
                            }
                        />
                    </div>
                </div>
                <div style={styles.footer}>
                    <Button
                        color={'github'}
                        round
                        onClick={onClose}
                    >
                        Cancelar
                    </Button>
                    <Button
                        color={'hust'}
                        onClick={handleSalvar}
                    >
                        Salvar
                    </Button>
                </div>
            </div>
        </div>
    );
};

export const showModalEditarMensagem = (mensagemOriginal, id_mensagem) => {
    return new Promise((resolve) => {
        const modalRoot = document.createElement('div');
        document.body.appendChild(modalRoot);

        const handleClose = (value) => {
            ReactDOM.unmountComponentAtNode(modalRoot);
            document.body.removeChild(modalRoot);
            resolve(value);
        };

        ReactDOM.render(
            <ModalEditarMensagem
                mensagemOriginal={mensagemOriginal}
                onClose={() => handleClose(null)}
                onSalvar={handleClose}
            />,
            modalRoot
        );
    });
};

export default ModalEditarMensagem;
