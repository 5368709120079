import React, {useState, useMemo, useEffect} from 'react';
import ReactDOM from 'react-dom';
import { AccountCircle, Close } from '@material-ui/icons';
import { WebService } from '../../components/WebService';
import { hustColorPrimary } from 'assets/jss/material-dashboard-pro-react.js';
import Swal from "sweetalert2";
import Button from "../../components/CustomButtons/Button";
import {useStyles} from "../NewChat/styles";
import {getFotoLink} from "../../components/Funcoes";
import {FaLongArrowAltLeft, FaLongArrowAltRight} from "react-icons/fa";

let timeoutBuscaContatos;
const ModalEncaminhar = ({ acao, textoMensagem, onClose, onEncaminhar, getContacts }) => {
    const classes = useStyles({ sent: true });
  const [busca, setBusca] = useState('');
  const [selecionados, setSelecionados] = useState([]);
  const [activeTab, setActiveTab] = useState('contatos');
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [carregandoMais, setCarregandoMais] = useState(false);
  const [localContatos, setLocalContatos] = useState([]);
  const [novoContato, setNovoContato] = useState({ nome: '', telefone: '', id_contato: '', id_whatsapp: '' });
  const [contatosAgrupados, setContatosAgrupados] = useState([]);

    useEffect(() => {
        carregarPaginaAtual(0);
      setPaginaAtual(0);
    }, []);

    const getContactsLocal = async (filter, page) => {
      const filteredContatos = await getContacts(page || 0, filter);
      const grupos = filteredContatos.reduce((acc, contato) => {
          const inicial = contato.nome?.charAt(0).toUpperCase() || '#';
          const key = /[A-Z]/.test(inicial) ? inicial : '#outros contatos';
          if (!acc[key]) acc[key] = [];
          acc[key].push(contato);
          return acc;
      }, {});

      setContatosAgrupados(Object.entries(grupos).sort((a, b) => {
          if (a[0] === '#outros contatos') return 1;
          if (b[0] === '#outros contatos') return -1;
          return a[0].localeCompare(b[0]);
      }));
    };

    useEffect(() => {
      clearInterval(timeoutBuscaContatos);
      timeoutBuscaContatos = setTimeout(() => getContactsLocal(busca), 700);
    }, [busca]);

    const carregarPaginaAtual = async (novaPagina) => {
        try {
            setCarregandoMais(true);

            await getContactsLocal(busca, novaPagina);
            setPaginaAtual(novaPagina);
            setCarregandoMais(false);
            return;
            const novosContatos = await getContacts(novaPagina);

            setLocalContatos((prevContatos) => {
                // Mapeia os novos contatos e garante que cada contato tenha um nome
                const contatosAtuais = novosContatos.map(contato => ({
                    ...contato,
                    nome: contato.nome
                }));

                // Filtra os contatos para garantir que são únicos
                const contatosUnicos = contatosAtuais.filter(
                    (value, index, self) =>
                        index === self.findIndex((t) => t.id_whatsapp === value.id_whatsapp)
                );
                return contatosUnicos; // Retorna apenas os contatos da página atual
            });

            setPaginaAtual(novaPagina);
        } catch (error) {
            console.error(`Erro ao carregar contatos para a página: ${novaPagina}`, error);
        } finally {
            setCarregandoMais(false);
        }
    };

    const handleEncaminhar = () => {
    if (selecionados.length > 0) {
      onEncaminhar(selecionados);
    }
  };

    const buscarContato = async () => {
        const { ok, contato } = await WebService(`/contato/getContatoInfo`, {
            numero: novoContato.telefone,
        });

        if (ok && contato) {
            // Desestruturação segura com valores padrão
            const {
                foto_perfil = "",
                id_whatsapp = "",
                id_contato = "",
                nome = "",
            } = contato;

            const contatoAtualizado = {
                ...novoContato, // Garante que outros campos não sejam perdidos
                nome: nome,
                id_whatsapp: id_whatsapp,
                id_contato: id_contato,
                fotoPerfil: foto_perfil,
                telefone: id_whatsapp ? id_whatsapp.split('@')[0] : "",
            };

            // Atualiza o estado
            setNovoContato(contatoAtualizado);

            console.log("novo contato atualizado: ", contatoAtualizado);

            // Exibe swal indicando que o contato foi encontrado
            await Swal.fire({
                title: 'Contato Encontrado!',
                text: `Nome: ${nome || "Sem nome cadastrado"}`,
                icon: 'success',
            });
        } else {
            const contatoNaoEncontrado = {
                ...novoContato,
                nome: 'Contato não encontrado',
                id_contato: '',
                id_whatsapp: '',
                fotoPerfil: '',
                telefone: novoContato.telefone,
            };

            // Atualiza o estado para contato não encontrado
            setNovoContato(contatoNaoEncontrado);

            console.log("novo contato não encontrado: ", contatoNaoEncontrado);

            // Exibe swal indicando que o contato não foi encontrado
            Swal.fire({
                title: 'Contato Não Encontrado!',
                text: 'Não conseguimos localizar o contato, tente novamente.',
                icon: 'error',
            });
        }
    };

    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
            fontFamily: 'NunitoRegular',
          zIndex: 1000,
        }}
        onClick={onClose}
      >
        <div
          onClick={e => e.stopPropagation()}
          style={{
            backgroundColor: '#fff',
            width: '90%',
            maxWidth: '400px',
            borderRadius: '24px',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
            maxHeight: '80vh',
          }}
        >
          {/* Cabeçalho */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '5px 10px',
            }}
          >
            <p style={{ fontSize: '20px', color: '#333', margin: 0, padding: '8px 0' }}>{acao}</p>
            <button
              onClick={onClose}
              style={{
                background: 'none',
                border: 'none',
                cursor: 'pointer',
                fontSize: '20px',
                color: '#666',
              }}
            >
              <Close style={{ fontSize: 24, color: '#666' }} />
            </button>
          </div>

          {/* Mensagem a ser encaminhada */}
          {/*<div style={{ padding: '0 16px 16px', backgroundColor: '#f5f5f5', margin: '0 16px 16px', borderRadius: '8px' }}>*/}
          {/*  <p style={{ fontSize: '14px', color: '#333', margin: 0, padding: '8px 0' }}>*/}
          {/*    Mensagem a ser encaminhada: <strong>"{textoMensagem}"</strong>*/}
          {/*  </p>*/}
          {/*</div>*/}

          {/* Tabs */}
          <div
            style={{
              display: 'flex',
              padding: '0 16px',
            }}
          >
            <button
              onClick={() => setActiveTab('contatos')}
              style={{
                flex: 'none',
                textAlign: 'center',
                padding: '8px 0',
                fontWeight: '600',
                color: activeTab === 'contatos' ? '#ff3e3e' : '#888',
                backgroundColor: 'transparent',
                border: 'none',
                borderBottom: activeTab === 'contatos' ? '2px solid #ff3e3e' : '2px solid transparent',
                cursor: 'pointer',
                marginRight: '24px',
                fontSize: '14px',
              }}
            >
              CONTATOS
            </button>
            <button
              onClick={() => setActiveTab('novo')}
              style={{
                flex: 'none',
                textAlign: 'center',
                padding: '8px 0',
                fontWeight: '600',
                color: activeTab === 'novo' ? '#ff3e3e' : '#888',
                backgroundColor: 'transparent',
                border: 'none',
                borderBottom: activeTab === 'novo' ? '2px solid #ff3e3e' : '2px solid transparent',
                cursor: 'pointer',
                fontSize: '14px',
              }}
            >
              NOVO
            </button>
          </div>

          {activeTab === 'contatos' ? (
            <>
              {/* Busca */}
              <div>
                <div
                  style={{
                    position: 'relative',
                    display: 'flex',
                      flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      // gap: '4px',
                    }}
                  >
                    <Button
                      color={'hust'}
                      simple
                      round
                      size={'sm'}
                      onClick={() => carregarPaginaAtual(paginaAtual - 1)}
                      disabled={paginaAtual === 0 || carregandoMais}
                    >
                      {carregandoMais ? '...' : <FaLongArrowAltLeft />}
                    </Button>

                    <span
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontWeight: '500',
                        fontSize: '14px',
                        color: '#666',
                        backgroundColor: '#f5f5f5',
                        padding: '6px 16px',
                        borderRadius: '20px',
                        minWidth: '100px',
                        textAlign: 'center',
                      }}
                    >
                      Página {paginaAtual + 1}
                    </span>

                    <Button
                      color={'hust'}
                      simple
                      round
                      size={'sm'}
                      onClick={() => carregarPaginaAtual(paginaAtual + 1)}
                      disabled={carregandoMais}
                    >
                      {carregandoMais ? '...' : <FaLongArrowAltRight />}
                    </Button>
                  </div>
                  <input
                    type="text"
                    placeholder="Buscar"
                    value={busca}
                    onChange={e => setBusca(e.target.value)}
                    style={{
                      width: 'calc(100% - 20px)',
                      padding: '12px 16px',
                      borderRadius: '24px',
                      border: '1px solid #ddd',
                      fontSize: '14px',
                      outline: 'none',
                      marginBottom: 5
                    }}
                  />
                </div>
              </div>

              {/* Lista de Contatos */}
              <div
                style={{
                  maxHeight: '300px',
                  overflowY: 'auto',
                  padding: '0 16px',
                }}
              >
                {contatosAgrupados.map(([inicial, grupoContatos]) => (
                  <div key={inicial}>
                    {/* Letra do Alfabeto */}
                    <div
                      color={hustColorPrimary[0]}
                      style={{
                        padding: '8px 16px',
                        color: hustColorPrimary[0],
                        fontWeight: '600',
                        fontSize: '18px',
                        backgroundColor: '#f9f9f9',
                        borderRadius: '8px',
                        marginBottom: '8px',
                      }}
                    >
                      {inicial}
                    </div>

                    {/* Lista de Contatos */}
                    {grupoContatos.map(contato => (
                      <div
                        key={contato.id_whatsapp}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          padding: '12px 16px',
                          borderRadius: '8px',
                          backgroundColor: selecionados.includes(contato.id_contato) ? 'var(--hust-light)' : '#fff',
                          cursor: 'pointer',
                          transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
                          boxShadow: selecionados.includes(contato.id_contato)
                            ? '0 4px 6px rgba(0, 0, 0, 0.1)'
                            : 'none',
                          marginBottom: '8px',
                        }}
                        onClick={() => {
                          setSelecionados(prev =>
                            prev.includes(contato.id_contato)
                              ? prev.filter(id => id !== contato.id_contato)
                              : [...prev, contato.id_contato],
                          );
                        }}
                      >
                        {/* Foto ou Ícone */}
                        {contato.foto_perfil ? (
                          <img
                            src={getFotoLink(contato.foto_perfil)}
                            alt={contato.nome}
                            style={{
                              width: '48px',
                              height: '48px',
                              borderRadius: '50%',
                              objectFit: 'cover',
                              marginRight: '12px',
                              flexShrink: 0,
                              border: selecionados.includes(contato.id_contato) ? '2px solid var(--hust)' : 'none',
                            }}
                          />
                        ) : (
                          <AccountCircle
                            style={{
                              width: 48,
                              height: 48,
                              color: '#ddd',
                              marginRight: '12px',
                              flexShrink: 0,
                            }}
                          />
                        )}

                        {/* Nome e Telefone */}
                        <div style={{ flex: 1, minWidth: 0 }}>
                          <p
                            style={{
                              fontWeight: '600',
                              fontSize: '16px',
                              color: '#333',
                              margin: 0,
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {contato.nome} {contato.nome && (<br/>)}
                            <small style={{fontWeight: 100}}>{contato.id_whatsapp.split('@')[0]}</small>
                          </p>
                          <p
                            style={{
                              fontSize: '14px',
                              color: '#666',
                              margin: 0,
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {contato.telefone}
                          </p>
                        </div>

                        {/* Ícone de Seleção */}
                        <div
                          style={{
                            width: '24px',
                            height: '24px',
                            borderRadius: '50%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginLeft: '12px',
                            border: selecionados.includes(contato.id_contato) ? hustColorPrimary[1] : '2px solid #ddd',
                          }}
                        >
                          {selecionados.includes(contato.id_contato) && (
                            <span style={{ color: hustColorPrimary[0], fontWeight: 'bold' }}>✓</span>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </>
          ) : (
            <div style={{ padding: '16px' }}>
              <p style={{ fontSize: '14px', color: '#666', marginBottom: '8px' }}>
                Busque por um contato ou busque por um número:
              </p>
              <input
                type="text"
                placeholder="Ex.: @novo_contato"
                value={novoContato.telefone}
                onChange={e => setNovoContato({ ...novoContato, telefone: e.target.value })}
                style={{
                  width: '100%',
                  padding: '12px 16px',
                  borderRadius: '24px',
                  border: '1px solid #ddd',
                  fontSize: '14px',
                  outline: 'none',
                  marginBottom: '16px',
                }}
              />
              <button
                onClick={buscarContato}
                style={{
                  backgroundColor: '#ff3e3e',
                  color: '#fff',
                  border: 'none',
                  borderRadius: '24px',
                  padding: '12px 24px',
                  cursor: 'pointer',
                  fontSize: '14px',
                  fontWeight: '500',
                  width: '100%',
                }}
              >
                BUSCAR
              </button>
              {novoContato.id_whatsapp && (
                <div
                  onClick={() => {
                    setSelecionados(prevSelecionados =>
                      prevSelecionados.includes(novoContato.id_contato)
                        ? prevSelecionados.filter(id => id !== novoContato.id_contato)
                        : [...prevSelecionados, novoContato.id_contato],
                    );
                  }}
                  style={{
                    padding: '12px 16px',
                    borderRadius: '8px',
                    borderBottom: '1px solid #ddd',
                    marginBottom: '8px',
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: selecionados.includes(novoContato.id_whatsapp) ? 'var(--hust)' : '#fff',
                    cursor: 'pointer',
                    transition: 'background-color 0.3s ease',
                    boxShadow: selecionados.includes(novoContato.id_whatsapp) ? '0 4px 6px rgba(0, 0, 0, 0.1)' : 'none',
                  }}
                >
                  {/* Foto ou Ícone de Usuário */}
                  {novoContato.foto_perfil ? (
                    <img
                      src={getFotoLink(novoContato.foto_perfil)}
                      alt={novoContato.nome}
                      style={{
                        width: '48px',
                        height: '48px',
                        borderRadius: '50%',
                        objectFit: 'cover',
                        marginRight: '12px',
                        flexShrink: 0,
                        border: '2px solid var(--hust)',
                      }}
                    />
                  ) : (
                    <AccountCircle
                      style={{
                        width: '48px',
                        height: '48px',
                        color: '#bbb',
                        marginRight: '12px',
                        flexShrink: 0,
                      }}
                    />
                  )}

                  {/* Nome e Telefone */}
                  <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    <p
                      style={{
                        fontWeight: '600',
                        margin: '0 0 4px 0',
                        fontSize: '16px',
                        color: '#333',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {novoContato.nome}
                    </p>
                    <p
                      style={{
                        margin: 0,
                        fontSize: '14px',
                        color: '#666',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {novoContato.telefone}
                    </p>
                  </div>

                  {/* Ícone de Seleção */}
                  {selecionados.includes(novoContato.id_contato) ? (
                    <div
                      style={{
                        width: '24px',
                        height: '24px',
                        borderRadius: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginLeft: '12px',
                        border: selecionados.includes(novoContato.id_contato) ? hustColorPrimary[1] : '2px solid #ddd',
                      }}
                    >
                      {selecionados.includes(novoContato.id_contato) && (
                        <span style={{ color: hustColorPrimary[0], fontWeight: 'bold' }}>✓</span>
                      )}
                    </div>
                  ) : (
                    <span
                      style={{
                        color: '#bbb',
                        fontSize: '20px',
                        marginLeft: '12px',
                      }}
                    >
                      ○
                    </span>
                  )}
                </div>
              )}
            </div>
          )}

          {/* Rodapé */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '16px',
              borderTop: '1px solid #eee',
              marginTop: 'auto',
            }}
          >
            <Button
              color={'simple'}
              style={{
                backgroundColor: '#939393',
                color: '#FFF',
                padding: '10px 15px',
                border: 'none',
                borderRadius: '30px',
                cursor: 'pointer',
              }}
              onClick={onClose}
            >
              CANCELAR
            </Button>
            <Button
              color={'hust'}
              onClick={handleEncaminhar}
              style={{
                backgroundColor: '#ff3e3e',
                color: '#fff',
                border: 'none',
                borderRadius: '24px',
                padding: "10px 2color={'hust'}4px",
                cursor: 'pointer',
                fontSize: '14px',
                fontWeight: '500',
                opacity: selecionados.length > 0 ? 1 : 0.7,
              }}
              disabled={selecionados.length === 0}
            >
              ENCAMINHAR
            </Button>
          </div>
        </div>
      </div>
    );
};

export const showModalEncaminhar = (acao, textoMensagem, getContacts) => {
    return new Promise(resolve => {
        const modalRoot = document.createElement('div');
        document.body.appendChild(modalRoot);

        const handleClose = value => {
            ReactDOM.unmountComponentAtNode(modalRoot);
            document.body.removeChild(modalRoot);
            resolve(value);
        };

        ReactDOM.render(
            <ModalEncaminhar
                acao={acao}
                textoMensagem={textoMensagem}
                onClose={() => handleClose(null)}
                getContacts={getContacts}
                onEncaminhar={handleClose}
            />,
            modalRoot,
        );
    });
};

export default ModalEncaminhar;
